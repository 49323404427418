
/*Overwrites required due whatsapp vue library */
h1 { background: none !important;}

a:link,
a:visited {
  color: #5cddbe !important;
  text-decoration: none;
  transition: all 0.4s ease-in-out;
}

a.btn-primary, a.btn-info {

  color: black;
  &:link,
  &:active,
  &:visited {
    color: black !important;
  }
}

a.btn-info.active {
    border-bottom: 1px solid #4BA663 ;
}

small {
  color: #ffffff;
}

p {
  font-weight: 500;
}

.vue-simple-drawer {
  background-color: transparent !important;
}

.nav-item {
  a {
    font-weight: normal;
  }
}

a {
  font-weight: bold;
}

//.swal2-popup {
//  //background: radial-gradient(#282626, #0a0a0a) !important;
//  //background: linear-gradient(#282626, #0a0a0a) !important;
//  background: linear-gradient(#23bac4, #66d0dd) !important;
//}

.swal2-html-container {
  overflow: hidden !important;
}

.v-popper__inner {
  background-color: #2b2a2a;
}

.v-popper--theme-dropdown .v-popper__inner {
  padding: 5px;
  background-color: #2b2a2a !important;
}

.fr-bordered {
  border: 1px solid #42CABE;
}

.fr-dib {
  opacity: 0.9;
  padding: 1px;
  margin-top: 20px;
  margin-bottom: 20px;
  border: 3px solid rgb(32, 34, 32);
  border-radius: 4px;
}

.btn-alt {
  font-weight: bolder;
  color: #d6cbcb !important;
  border: 1px solid seashell;
  background-color: #2e3241 !important;
}

.header-alt {
  color: #ffffff !important;
  text-shadow: black 1px 1px 1px;
}

//.swal2-confirm {
//  font-weight: bolder;
//  color: #d6cbcb !important;
//  border: 1px solid seashell;
//  background-color: #2e3241 !important;
//}
.btn-link {
  &.active {
    background-color: #434141 !important;
  }
}





