 // Core

@import "dashboard/custom/functions";
@import "dashboard/bootstrap/functions";

@import "dashboard/custom/variables";
@import "dashboard/bootstrap/variables";

@import "dashboard/custom/mixins";
@import "dashboard/bootstrap/mixins";

 // Bootstrap components

@import "dashboard/bootstrap/root";
@import "dashboard/bootstrap/reboot";
@import "dashboard/bootstrap/type";
@import "dashboard/bootstrap/images";
//@import "dashboard/bootstrap/code";
@import "dashboard/bootstrap/grid";
@import "dashboard/bootstrap/tables";
//@import "dashboard/bootstrap/forms";
@import "dashboard/bootstrap/buttons";
@import "dashboard/bootstrap/transitions";
//@import "dashboard/bootstrap/dropdown";
@import "dashboard/bootstrap/button-group";
@import "dashboard/bootstrap/input-group";
//@import "dashboard/bootstrap/custom-forms";
@import "dashboard/bootstrap/nav";
@import "dashboard/bootstrap/navbar";
@import "dashboard/bootstrap/card";
//@import "dashboard/bootstrap/breadcrumb";
@import "dashboard/bootstrap/pagination";
@import "dashboard/bootstrap/badge";
//@import "dashboard/bootstrap/jumbotron";
@import "dashboard/bootstrap/alert";
//@import "dashboard/bootstrap/progress";
@import "dashboard/bootstrap/media";
//@import "dashboard/bootstrap/list-group";
//@import "dashboard/bootstrap/close";
@import "dashboard/bootstrap/modal";
@import "dashboard/bootstrap/tooltip";
//@import "dashboard/bootstrap/popover";
//@import "dashboard/bootstrap/carousel";
@import "dashboard/bootstrap/utilities";
//@import "dashboard/bootstrap/print";

 // Custom components

 @import "dashboard/custom/reboot.scss";
 @import "dashboard/custom/global.scss";
 @import "dashboard/custom/utilities.scss";
 //@import "dashboard/custom/alerts.scss";
 //@import "dashboard/custom/avatars.scss";
 @import "dashboard/custom/badge.scss";
 @import "dashboard/custom/buttons.scss";
 //@import "dashboard/custom/close.scss";
 //@import "dashboard/custom/custom-forms.scss";
 //@import "dashboard/custom/dropdown.scss";
 @import "dashboard/custom/footer.scss";
 //@import "dashboard/custom/forms.scss";
 @import "dashboard/custom/grid.scss";
 @import "dashboard/custom/icons.scss";
 @import "dashboard/custom/images.scss";
 //@import "dashboard/custom/input-group.scss";
 //@import "dashboard/custom/info-areas.scss";
 //@import "dashboard/custom/modal.scss";
 @import "dashboard/custom/nav.scss";
 @import "dashboard/custom/navbar.scss";
 //@import "dashboard/custom/pagination.scss";
 @import "dashboard/custom/tabs.scss";
 //@import "dashboard/custom/pills.scss";
 //@import "dashboard/custom/popover.scss";
 //@import "dashboard/custom/progress.scss";
 @import "dashboard/custom/type.scss";
 @import "dashboard/custom/tables";
 //@import "dashboard/custom/checkboxes-radio";
 //@import "dashboard/custom/fixed-plugin";
 @import "dashboard/custom/sidebar-and-main-panel.scss";
 @import "dashboard/custom/misc.scss";
 //@import "dashboard/custom/rtl.scss";
 @import "dashboard/custom/timeline.scss";

 // Vendor / Plugins

//@import "dashboard/custom/vendor/plugin-datetimepicker.scss";
//@import "dashboard/custom/vendor/plugin-nouislider.scss";
//@import "dashboard/custom/vendor/plugin-fullcalendar.scss";
@import "dashboard/custom/vendor/plugin-sweetalert2.scss";
//@import "dashboard/custom/vendor/plugin-bootstrap-switch.scss";
//@import "dashboard/custom/vendor/plugin-bootstrap-select.scss";
//@import "dashboard/custom/vendor/plugin-jasny-fileupload.scss";
//@import "dashboard/custom/vendor/plugin-tagsinput.scss";
@import "dashboard/custom/vendor/plugin-perfect-scrollbar.scss";
//@import "dashboard/custom/vendor/plugin-jquery.jvectormap.scss";
//@import "dashboard/custom/vendor/plugin-datatables.net.scss";
@import "dashboard/custom/vendor/plugin-animate-bootstrap-notify.scss";
//@import "dashboard/custom/vendor/plugin-card-wizard.scss";

// Vendor Element UI plugins
//@import "dashboard/custom/vendor/element-ui/plugin-tags";
//@import "dashboard/custom/vendor/element-ui/plugin-slider";
//@import "dashboard/custom/vendor/element-ui/plugin-select";
//@import "dashboard/custom/vendor/element-ui/plugin-inputs";
//@import "dashboard/custom/vendor/element-ui/plugin-tables";
//@import "dashboard/custom/vendor/element-ui/plugin-datepicker";

// example pages and sections

//@import "dashboard/custom/example-pages.scss";

// light mode

//@import "dashboard/custom/white-content.scss";

// Cards

@import 'dashboard/custom/card';
//@import "dashboard/custom/cards/card-chart";
//@import "dashboard/custom/cards/card-map";
//@import "dashboard/custom/cards/card-user";
//@import "dashboard/custom/cards/card-task";
@import "dashboard/custom/cards/card-animation-on-hover";
@import "dashboard/custom/cards/card-collapse";
//@import "dashboard/custom/cards/card-contributions";
//@import "dashboard/custom/cards/card-lock";
//@import "dashboard/custom/cards/card-pricing";
//@import "dashboard/custom/cards/card-profile";
//@import "dashboard/custom/cards/card-plain";
//@import "dashboard/custom/cards/card-register";
//@import "dashboard/custom/cards/card-stats";
//@import "dashboard/custom/cards/card-subcategories";
//@import "dashboard/custom/cards/card-testimonials";
//@import "dashboard/custom/cards/card-wizard";
 @import "dashboard/custom/carrousel";

.directions-map {
  filter: grayscale(100%);
  border: 5px solid black;
}

.Cookie--black {
  background-color: black;
  padding: 15px;
  font-weight: bold;
  border-top: 1px solid #66cdaa;
}

 .select {
   font-size: 1rem;
   line-height: 1.1;
   background-color: #fff;
   background-image: linear-gradient(to top, #f9f9f9, #fff 33%);
 }

 .text-smart {
   text-align: left;
 }

 @media only screen and (max-width: 600px) {
   .text-smart {
     text-align: center;
   }
 }

 .detail-table-name {
   text-transform: uppercase !important;
   font-size: 11px;
   font-weight: bolder;
 }

 .detail-table-value {
   text-transform: capitalize !important;
   font-size: 12px;
   margin-bottom: 10px;
 }

 .nav-tabs {
   display: flex;
   border-bottom: 0.5px solid gray;
   li:active, a:active, li.active {
     border-bottom: 1px solid aquamarine;
   }
   .nav-item {
     a {
       cursor: pointer;
     }
     li:active, a:active {
       background-color: #00f2c3;
       font-weight: bold;
     }
   }
 }

 .footer {
   ul {
     padding-block-start: 0px;
     padding-inline-start: 0px;
   }
 }

 .fade-enter-active {
   transition: opacity 1s ease-in-out;
 }

 .fade-enter-to {
   opacity: 1;
 }

 .fade-enter {
   opacity: 0;
 }
 .centered {
   margin-left: auto;
   margin-right: auto;
   left: 0;
   right: 0;
 }

 .section-header {
   margin-top: 1px;
   width: 100%;
   font-weight: bold;
   padding: 8px;
   background-color: #262424;
 }

 @keyframes placeHolderShimmer{
   0%{
     background-position: -468px 0
   }
   100%{
     background-position: 468px 0
   }
 }

 .loading-text {
   animation-duration: 1.25s;
   animation-fill-mode: forwards;
   animation-iteration-count: infinite;
   animation-name: placeHolderShimmer;
   animation-timing-function: linear;
   background: #262424;
   background: linear-gradient(to right, #1e3434, #262424);
   background-size: 800px 104px;
   min-height: 300px;
   position: relative;
 }

 .large-loading-text {
   @extend .loading-text;
   min-height: 800px;
 }

 #article-summary {
   li {
     font-weight: lighter;
   }
 }

 .image-circle {
   border: 1px solid #262424;border-radius: 100px;
 }


 .tulum-party-title {
   font-family: 'Oswald', sans-serif;
   font-style: normal;
   font-weight: bold;
   font-size: 2.2rem;
   color: #efefef !important;

 }

 .tulum-party-subtitle {
   font-family: 'Oswald', sans-serif;
   color: #efefef !important;
   line-height: 140%;
   font-style: normal;
   font-weight: 900;
 }


 .alt-font {
   font-family: 'Oswald', sans-serif !important;
  }

 .tulum-party-title-alt {
   @extend .tulum-party-title;
   text-transform: uppercase;
 }

 .calendarPoint {
   border-radius: 100px;
   border: 2px solid #13455c ;
   background: #ba855e;
   width: 0;
   padding: 3px;
   max-height: 5px;
 }

 .tim-icons-astronaut {
   height: 30px;
   padding: 13px;
   background-image: url("https://imgix.cosmicjs.com/1098dee0-e8ea-11ef-b333-e101bec29f3d-atro-transparent.png?w=40&h=40&fit=scale");
 }

 .btn-block {

   display: block;
 }

 .flex-container {
   display: flex;
   margin-left: 15px;
   justify-content: left; /* Center items horizontally */
   align-items: center; /* Center items vertically */
   flex-direction: row; /* items are in a row */
   gap: 30px; /* creates a gap between the items */
 }

 .flex-container-col {
   display: flex;
   justify-content: left; /* Center items horizontally */
   align-items: flex-start; /* Center items vertically */
   flex-direction: column; /* items are in a row */
   gap: 0px; /* creates a gap between the items */
 }

 @keyframes img-transtion {
   0% {
     background: #79685e;
     //background: #ee7752;
   }

   20% {
     background: rgba(238, 119, 82, 0.5)
     //background: #ee7752;
   }

   100% {
     background: #1b1b1b;

     //background: linear-gradient(180deg, black, #ee7752 );
   }
 }


 .active-input {
   border: 1px solid mediumaquamarine;
   border-radius: 4px;
 }


 .img-animation {
   animation:    img-transtion 40s ease-in-out infinite ;
 }

 .star {
   fill: gold;
   stroke: gray;
   stroke-width: 1;
 }
 .star-container {
   display: inline-flex;
 }
 //https://www.color-hex.com/color-palette/5643
 //#000000
 //#003e3e
 //#007c7c
 //#00baba
 //#00f8f8
